import type { Message, RegisterOptions } from 'react-hook-form';
import type { Condition } from '../form-conditions';

export enum QuestionTypes {
  multipleChoice = 'multipleChoice',
  currency = 'currency',
  date = 'date',
}

export enum QuestionStyles {
  heading = 'heading',
  label = 'label',
}

export interface Option<OptionValues = string> {
  label?: string;
  value: OptionValues;
}
[];

type Rule = Partial<
  Pick<
    RegisterOptions,
    | 'required'
    | 'min'
    | 'max'
    | 'maxLength'
    | 'minLength'
    | 'pattern'
    | 'validate'
  >
>;
export interface Validation {
  level: 'error' | 'warning' | 'info';
  message?: Message;
  conditions?: Condition[];
  rules: Rule;
}

type SoftRangeConstraint = {
  min: number;
  max: number;
  message: string;
};

interface BaseQuestion<QuestionIds = string, OptionValues = string> {
  id: QuestionIds;
  type: QuestionTypes;
  style: QuestionStyles;
  defaultValue?: OptionValues;
  question: string;
  instruction?: string;
  note?: string;
  validations?: Rule | Validation[];
  conditions?: Condition<QuestionIds, OptionValues>[];
  maxInputLength?: number;
  shouldFormatValue?: boolean;
  perMonth?: boolean;
}

export interface MultipleChoiceQuestion<
  QuestionIds = string,
  OptionValues = string
> extends BaseQuestion<QuestionIds> {
  options: Option<OptionValues>[];
}

export interface NumericQuestion<QuestionIds = string>
  extends BaseQuestion<QuestionIds> {
  positive?: boolean;
  noExponent?: boolean;
  isOnlyIntegers?: boolean;
}

export interface CurrencyQuestion<QuestionIds = string>
  extends NumericQuestion<QuestionIds> {
  softRangeConstraint?: SoftRangeConstraint;
}

export type NumberQuestion<QuestionIds = string> = NumericQuestion<QuestionIds>;

export type Question<QuestionIds = string, OptionValues = string> =
  | MultipleChoiceQuestion<QuestionIds, OptionValues>
  | CurrencyQuestion<QuestionIds>
  | NumberQuestion<QuestionIds>;
