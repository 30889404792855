/* eslint-disable sonarjs/no-duplicate-string */

import {
  EmploymentStatus,
  ResidentialStatus,
  MaritalStatus,
  DependantsCount,
  FinancialSituation,
  GeneralQuestions,
} from '@newday/plum-types';
import { commonDateValidators } from '@newday/core';

import { QuestionTypes, QuestionStyles } from '@newday/plum-form';
import type { Question } from '@newday/plum-form';

import { inputConstraints } from './input-constraints';

const employmentStatusQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.employmentStatus,
  type: QuestionTypes.multipleChoice,
  style: QuestionStyles.heading,
  question: 'What’s your employment status?',
  options: [
    {
      label: 'Employed full-time',
      value: EmploymentStatus.fullTime,
    },
    {
      label: 'Self-employed',
      value: EmploymentStatus.selfEmployed,
    },
    {
      label: 'Employed part-time',
      value: EmploymentStatus.partTime,
    },
    {
      label: 'Homemaker',
      value: EmploymentStatus.homemaker,
    },
    {
      label: 'Retired',
      value: EmploymentStatus.retired,
    },
    {
      label: 'Unemployed',
      value: EmploymentStatus.unemployed,
    },
    {
      label: 'Student',
      value: EmploymentStatus.student,
    },
    {
      label: 'Other',
      value: EmploymentStatus.other,
    },
  ],
  validations: {
    required: 'Please select your employment status',
  },
};

const grossAnnualIncomeQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.grossAnnualIncome,
  type: QuestionTypes.currency,
  style: QuestionStyles.heading,
  question: 'What’s your annual income?',
  instruction: 'Include salary, pension and benefits each year before tax.',
  note: 'You may need to send us proof of this income to secure a loan offer.',
  validations: [
    {
      level: 'error',
      rules: {
        required: 'Please enter an amount',
        maxLength: {
          value: 10,
          message:
            'This figure doesn’t look right. Please enter your yearly income',
        },
      },
    },
  ],
  softRangeConstraint: {
    min: 6000,
    max: 100000,
    message: 'Are you sure your income is correct?',
  },
  ...inputConstraints,
};

const employmentStartDateQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.employmentStartDate,
  type: QuestionTypes.date,
  style: QuestionStyles.heading,
  question: 'When did you become self-employed?',
  conditions: [
    {
      question: GeneralQuestions.employmentStatus,
      values: [EmploymentStatus.selfEmployed],
    },
  ],
  validations: [
    {
      level: 'error',
      rules: {
        required: 'Please tell us the date when you became self-employed',
        validate: {
          ...commonDateValidators,
          validRecentYear: ({ year }) => {
            const currentYear = new Date().getFullYear();
            return year >= currentYear - 100 || 'Please enter a valid year';
          },
        },
      },
    },
  ],
};

const maritalStatusQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.maritalStatus,
  type: QuestionTypes.multipleChoice,
  style: QuestionStyles.heading,
  question: 'What’s your marital status?',
  options: [
    {
      label: 'Married',
      value: MaritalStatus.married,
    },
    {
      label: 'Single',
      value: MaritalStatus.single,
    },
    {
      label: 'Divorced',
      value: MaritalStatus.divorced,
    },
    {
      label: 'Widowed',
      value: MaritalStatus.widowed,
    },
    {
      label: 'Living together',
      value: MaritalStatus.cohabiting,
    },
    {
      label: 'Separated',
      value: MaritalStatus.separated,
    },
  ],
  validations: {
    required: 'Please select your marital status',
  },
};

const dependantsCountQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.dependants,
  type: QuestionTypes.multipleChoice,
  style: QuestionStyles.heading,
  question: 'How many people are financially dependent on you?',
  defaultValue: DependantsCount.none,
  options: [
    {
      label: 'None',
      value: DependantsCount.none,
    },
    {
      label: '1',
      value: DependantsCount.one,
    },
    {
      label: '2',
      value: DependantsCount.two,
    },
    {
      label: '3+',
      value: DependantsCount.threeOrMore,
    },
  ],
  validations: {
    required: 'Please select an option',
  },
};

const otherHouseholdIncomeQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.otherHouseholdIncome,
  type: QuestionTypes.currency,
  style: QuestionStyles.heading,
  question: 'What’s your partner’s annual income?',
  instruction:
    'Include salary, pension and benefits each year before tax. If your partner’s income is not available to you to make repayments under this loan agreement, enter £0.',
  conditions: [
    {
      question: GeneralQuestions.maritalStatus,
      values: [MaritalStatus.married, MaritalStatus.cohabiting],
    },
  ],
  validations: [
    {
      level: 'error',
      rules: {
        required:
          'This figure doesn’t look right. Please enter your other household income',
      },
    },
  ],
  softRangeConstraint: {
    min: 0,
    max: 100000,
    message: 'Are you sure your partner’s income is correct?',
  },
  ...inputConstraints,
};

const educationCostQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.expenditureEducationCost,
  type: QuestionTypes.currency,
  style: QuestionStyles.heading,
  question: 'How much do you spend each month supporting dependants?',
  instruction:
    'Please tell us about any payments you make in a month towards childcare, spouse or child maintenance, school fees or other care such as disability and elderly care. If this doesn’t apply to you, enter £0.',
  note: '',
  conditions: [
    {
      question: GeneralQuestions.dependants,
      values: [
        DependantsCount.one,
        DependantsCount.two,
        DependantsCount.threeOrMore,
      ],
    },
  ],
  perMonth: true,
  softRangeConstraint: {
    min: 0,
    max: 3000,
    message: 'Are you sure your dependents’ education payments are correct?',
  },
  ...inputConstraints,
};

const residentialStatusQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.residentialStatus,
  type: QuestionTypes.multipleChoice,
  style: QuestionStyles.heading,
  question: 'What’s your residential status?',
  options: [
    {
      label: 'Home owner',
      value: ResidentialStatus.ownerOccupier,
    },
    {
      label: 'Private tenant',
      value: ResidentialStatus.otherTenant,
    },
    {
      label: 'Council tenant',
      value: ResidentialStatus.councilHousing,
    },
    {
      label: 'Living with parents',
      value: ResidentialStatus.livingWithParents,
    },
    {
      label: 'Other',
      value: ResidentialStatus.other,
    },
  ],
  validations: {
    required: 'Please select your residential status',
  },
};

const financialSituationQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.financialSituation,
  type: QuestionTypes.multipleChoice,
  style: QuestionStyles.heading,
  question:
    'How is your financial situation likely to look in the near future?',
  instruction:
    'Think about things such as planned retirement, redundancy or other changes to your income that may impact your personal finances.',
  options: [
    {
      label: 'Better',
      value: FinancialSituation.improve,
    },
    {
      label: 'Worse',
      value: FinancialSituation.decline,
    },
    {
      label: 'Unchanged',
      value: FinancialSituation.staySame,
    },
  ],
  validations: {
    required: 'Please select an answer',
  },
};

export {
  employmentStatusQuestion,
  grossAnnualIncomeQuestion,
  employmentStartDateQuestion,
  maritalStatusQuestion,
  dependantsCountQuestion,
  otherHouseholdIncomeQuestion,
  educationCostQuestion,
  residentialStatusQuestion,
  financialSituationQuestion,
};
