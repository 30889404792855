import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  RadioButton,
  RadioButtonGroup,
  Text,
  CurrencyInput,
  DateInput,
  Box,
  ValidationMessage,
  useTheme,
  useMediaQuery,
} from '@newday/core';
import {
  CurrencyQuestion,
  MultipleChoiceQuestion,
  NumberQuestion,
  QuestionStyles,
  QuestionTypes,
} from './form-question.types';
import { useConditionsMatched } from '../form-conditions';

import type { Question as QuestionType } from './form-question.types';
import type { ControllerRenderProps } from 'react-hook-form';

const Question: React.FC<{
  question: QuestionType;
  as: 'legend' | 'label';
}> = ({ question: questionData, as = 'label' }) => {
  const { id, question, instruction, style } = questionData;

  const htmlProps =
    as === 'legend' ? { as } : { as, htmlFor: id, display: 'block' };

  const styleProps =
    style == QuestionStyles.heading
      ? { fontSize: 'lg', color: 'brand.primary', fontWeight: 'bold' }
      : { fontSize: 'md', fontWeight: 'bold' };

  const mb = instruction ? 2 : 3;

  return (
    <Text {...htmlProps} {...styleProps} mb={mb}>
      {question}
    </Text>
  );
};

const Instruction: React.FC<{ instruction?: string }> = ({ instruction }) =>
  instruction ? (
    <Text fontSize="md" mb={3}>
      {instruction}
    </Text>
  ) : null;

interface MultipleChoiceInputProps {
  question: MultipleChoiceQuestion;
  field: Omit<ControllerRenderProps, 'ref'>;
  invalid: boolean;
}

const MultipleChoiceInput: React.FC<MultipleChoiceInputProps> = ({
  question: questionData,
  invalid,
  field,
}) => {
  const { options, instruction } = questionData;
  const theme = useTheme();
  const [isAboveMdViewport] = useMediaQuery(
    `(min-width: ${theme?.breakpoints?.md})`
  );

  return (
    <fieldset>
      <Question as="legend" question={questionData} />
      <Instruction instruction={instruction} />
      <RadioButtonGroup
        {...field}
        radioButtonsPerRow={isAboveMdViewport ? 4 : 2}
      >
        {({ getRadioButtonProps }) =>
          options.map(({ label, value }) => {
            const radio = getRadioButtonProps({ value });
            return (
              <RadioButton isInvalid={invalid} key={value} {...radio}>
                {label ?? value}
              </RadioButton>
            );
          })
        }
      </RadioButtonGroup>
    </fieldset>
  );
};

const TextInput: React.FC<{
  question: CurrencyQuestion | NumberQuestion;
  field: Omit<ControllerRenderProps, 'ref'>;
  invalid: boolean;
}> = ({ question: questionData, invalid, field }) => {
  const {
    id,
    instruction,
    type,
    perMonth,
    positive,
    noExponent,
    maxInputLength,
    isOnlyIntegers,
    shouldFormatValue,
  } = questionData;

  const softRangeConstraint = (questionData as CurrencyQuestion)
    .softRangeConstraint;
  const [warning, setWarning] = React.useState<string | null>(null);

  const warningBorderStyles = {
    borderColor: 'brand.tertiary',
    borderWidth: '2px',
  };

  const warningStyles = !!warning
    ? {
        ...warningBorderStyles,
        _hover: {
          ...warningBorderStyles,
        },
      }
    : {};

  return (
    <>
      <Question as="label" question={questionData} />
      <Instruction instruction={instruction} />
      {type === QuestionTypes.currency ? (
        <Box w={{ md: '50%' }}>
          <CurrencyInput
            id={id}
            isInvalid={invalid}
            positive={positive}
            noExponent={noExponent}
            maxInputLength={maxInputLength}
            isOnlyIntegers={isOnlyIntegers}
            shouldFormatValue={shouldFormatValue}
            rightElementContent={perMonth ? 'per month' : undefined}
            onWheelCapture={(e) => e.currentTarget.blur()}
            {...field}
            onBlur={() => {
              if (
                softRangeConstraint &&
                field.value &&
                (field.value > softRangeConstraint.max ||
                  field.value < softRangeConstraint.min)
              ) {
                setWarning(softRangeConstraint.message);
              } else {
                setWarning(null);
              }
              field.onBlur();
            }}
            {...warningStyles}
          />
          {warning && (
            <ValidationMessage mt={3} color="brand.primary" message={warning} />
          )}
        </Box>
      ) : null}
      {type === QuestionTypes.date ? (
        <DateInput
          id={id}
          isInvalid={invalid}
          month={field.value?.month}
          year={field.value?.year}
          {...field}
        />
      ) : null}
    </>
  );
};

interface AnswerInputProps {
  question: QuestionType;
  field: Omit<ControllerRenderProps, 'ref'>;
  invalid: boolean;
}

const AnswerInput: React.FC<AnswerInputProps> = ({
  question,
  field,
  invalid,
}) =>
  question.type === QuestionTypes.multipleChoice ? (
    <MultipleChoiceInput
      question={question as MultipleChoiceQuestion}
      invalid={invalid}
      field={field}
    />
  ) : (
    <TextInput
      question={question as CurrencyQuestion | NumberQuestion}
      invalid={invalid}
      field={field}
    />
  );

const Answer: React.FC<{
  question: QuestionType;
}> = ({ question: questionData }) => {
  const { control } = useFormContext();

  const rules = Array.isArray(questionData.validations)
    ? questionData.validations?.find(
        (validation) => validation.level === 'error'
      )?.rules
    : questionData.validations;

  return (
    <Controller
      defaultValue={questionData.defaultValue}
      name={questionData.id}
      control={control}
      rules={rules}
      render={({ field: { name, value, onChange, onBlur }, fieldState }) => {
        const field = { name, value, onChange, onBlur };
        const { invalid, error } = fieldState;

        return (
          <>
            <AnswerInput
              question={questionData}
              field={field}
              invalid={invalid}
            />
            {invalid && error?.message ? (
              <ValidationMessage mt={3} message={error.message} />
            ) : null}
          </>
        );
      }}
      shouldUnregister={true}
    />
  );
};

const FormQuestion: React.FC<{ question: QuestionType }> = ({
  question: questionData,
}) => {
  const { conditions, note } = questionData;
  const conditionsMatched = useConditionsMatched(conditions);

  return conditionsMatched ? (
    <Box py={5}>
      <Answer question={questionData} />
      {note ? (
        <Text fontSize="sm" mt={1} w={{ md: '50%' }}>
          {note}
        </Text>
      ) : null}
    </Box>
  ) : null;
};

export { FormQuestion };
