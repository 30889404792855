import React, { JSXElementConstructor } from 'react';
import { Story } from '@storybook/react';
import { HStack } from '@chakra-ui/react';
import type { IconProps } from './icon';

export const iconLayoutDecorator = (Story: Story) => (
  <HStack>
    <Story />
  </HStack>
);

export const generateStory = ({
  IconComponent,
  oneColorIcon = true,
}: {
  IconComponent: JSXElementConstructor<any> & {
    displayName?: string;
  };
  oneColorIcon: boolean;
}) => {
  const Template: Story<IconProps> = (args) => <IconComponent {...args} />;

  const VariantTemplate: Story<Pick<IconProps, 'size' | 'variant'>> = (
    args
  ) => (
    <>
      <IconComponent {...args} size="xs" />
      <IconComponent {...args} size="sm" />
      <IconComponent {...args} size="md" />
      {oneColorIcon ? null : <IconComponent {...args} size="lg" />}
    </>
  );

  const Default = Template.bind({});
  Default.args = { variant: 'primary', size: 'md' };

  const Primary = VariantTemplate.bind({});
  Primary.args = { variant: 'primary', size: 'md' };

  const Secondary = VariantTemplate.bind({});
  Secondary.args = { variant: 'secondary' };

  const Custom = Template.bind({});
  Custom.args = {
    color: 'purple',
    fill: oneColorIcon ? 'purple' : 'hotpink',
    width: '10rem',
    height: '10rem',
  };

  return {
    story: {
      component: IconComponent,
      title: `carrot-ui/Icons/${IconComponent?.displayName}`,
      decorators: [iconLayoutDecorator],
      parameters: {
        controls: { hideNoControlsWarning: true },
        docs: {
          source: {
            excludeDecorators: true,
          },
        },
      },
      argTypes: oneColorIcon ? iconArgTypes : twoColorIconArgTypes,
    },
    templates: oneColorIcon
      ? { Default, Primary, Custom }
      : { Default, Primary, Secondary, Custom },
  };
};

export const iconArgTypes = {
  width: { control: { type: 'text' } },
  height: { control: { type: 'text' } },
  color: { control: { type: 'text' } },
};

export const twoColorIconArgTypes = {
  variant: {
    control: 'radio',
    options: ['primary', 'secondary'],
  },
  size: {
    control: 'radio',
    options: ['xs', 'sm', 'md', 'lg'],
  },
  ...iconArgTypes,
  fill: { control: { type: 'text' } },
  stroke: { control: { type: 'text' } },
};

export const oneColorIconArgTypes = {
  size: {
    control: 'radio',
    options: ['xs', 'sm', 'md'],
  },
  ...iconArgTypes,
};
