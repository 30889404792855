/* eslint-disable sonarjs/no-duplicate-string */

import { GeneralQuestions } from '@newday/plum-types';

import { QuestionTypes, QuestionStyles } from '@newday/plum-form';
import type { Question } from '@newday/plum-form';

import { inputConstraints } from './input-constraints';

const accommodationCostsQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.accommodationCosts,
  type: QuestionTypes.currency,
  question: 'Rent or mortgage payments',
  style: QuestionStyles.label,
  validations: [
    {
      level: 'error',
      rules: {
        required: 'Please enter your monthly rent or mortgage amount',
      },
    },
  ],
  perMonth: true,
  softRangeConstraint: {
    min: 50,
    max: 4000,
    message: 'Are you sure your rent or mortgage payments are correct?',
  },
  ...inputConstraints,
};

const councilTaxQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.councilTax,
  type: QuestionTypes.currency,
  question: 'Council tax',
  style: QuestionStyles.label,
  validations: {
    required: 'Please enter your monthly council tax amount',
  },
  perMonth: true,
  softRangeConstraint: {
    min: 0,
    max: 500,
    message: 'Are you sure your council tax payments are correct?',
  },
  ...inputConstraints,
};

const expenditureUtilitiesCostQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.expenditureUtilitiesCost,
  type: QuestionTypes.currency,
  question: 'Utilities',
  style: QuestionStyles.label,
  note: 'Include electricity, gas, other fuels, water, broadband, TV and phone packages. ',
  perMonth: true,
  softRangeConstraint: {
    min: 0,
    max: 1000,
    message: 'Are you sure your utilities payments are correct?',
  },
  ...inputConstraints,
};

const otherQuestion: Question<GeneralQuestions> = {
  id: GeneralQuestions.expenditureOtherCost,
  type: QuestionTypes.currency,
  question: 'Other costs',
  style: QuestionStyles.label,
  note: 'Include groceries, transport, healthcare, insurance and any other regular expenses. Don’t include any debt repayments – like car loans, hire purchases or credit cards.',
  validations: {
    required: 'Please enter your other costs amount',
  },
  perMonth: true,
  softRangeConstraint: {
    min: 100,
    max: 2000,
    message: 'This figure doesn’t look right. Please enter your other costs',
  },
  ...inputConstraints,
};

export {
  accommodationCostsQuestion,
  councilTaxQuestion,
  expenditureUtilitiesCostQuestion,
  otherQuestion,
};
